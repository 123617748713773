import Layout from "../../hocs/Layout";
import { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  Col,
  Row,
  Table,
  Badge,
  Stack,
} from "react-bootstrap";
import { connect } from "react-redux";
import { get_contratos } from "../../services/contratos";
import { LuFileUp } from "react-icons/lu";
import { LuFiles } from "react-icons/lu";
import { CiWarning } from "react-icons/ci";
import GestionArchivos from "../../components/modals/GestionArchivos";
import GestionFacturas from "../../components/modals/GestionFacturas";
import SubirArchivo from "../../components/modals/SubirArchivo";
import PaginationComponent from "../../components/pagination/Pagination";

const estados = {
  activa: { descrip: "ACTIVA", bg: "success" },
  baixa: { descrip: "BAJA", bg: "danger" },
  cancelada: { descrip: "CANCELADA", bg: "danger" },
  esborrany: { descrip: "BORRADOR", bg: "primary" },
  impagament: { descrip: "IMPAGA", bg: "danger" },
  modcontractual: { descrip: "MOD. CONTRACTUAL ABIERTA", bg: "danger" },
  pendent: { descrip: "PENDIENTE", bg: "danger" },
  tall: { descrip: "ALTA", bg: "danger" },
  validar: { descrip: "VALIDAR", bg: "danger" },
};

const ContratosList = ({ user, isAuthenticated }) => {
  const [showSubirArchivo, setShowSubirArchivo] = useState(false);
  const [showGestionArchivos, setShowGestionArchivos] = useState(false);
  const [showGestionFacturas, setShowGestionFacturas] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contratoActivo, setContratoActivo] = useState(false);
  const [contratos, setContratos] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const getContratos = async (page) => {
      setLoading(true);
      setContratos(await get_contratos(user.prescriptor, page));
      setLoading(false);
    };
    if (isAuthenticated) {
      getContratos(currentPage);
    }
    window.scrollTo(0, 0);
  }, [isAuthenticated, user.prescriptor, currentPage]);

  const handlePageChange = (page) => { 
    setCurrentPage(page);
  };

  const getContratos = async () => {
    setLoading(true);
    setContratos(await get_contratos(user.prescriptor, currentPage));
    setLoading(false);
  };

  const openGestionArchivos = (id, name, sector_type) => {
    setContratoActivo({ id, name, sector_type });
    setShowGestionArchivos(true);
  };

  const openGestionFacturas= (id, name, sector_type) => {
    setContratoActivo({ id, name, sector_type });
    setShowGestionFacturas(true);
  };

  const openModalSubirArchivo = (id, name, sector_type) => {
    setContratoActivo({ id, name, sector_type });
    setShowSubirArchivo(true);
  };

  const stateBadge = (state) => {
    return (
      <Badge pill bg={estados[state].bg}>
        {estados[state].descrip}
      </Badge>
    );
  };

  const sectorTypeBadge = (sector_type) => {
    return sector_type === "electrico" ? (
      <Badge pill bg="warning" text="dark">
        ELECTRICIDAD
      </Badge>
    ) : (
      <Badge pill bg="info" text="dark">
        GAS
      </Badge>
    );
  };

  return (
    <Layout lg={10} md={12} title="Contratos">
      <div className="row justify-content-center align-items-center mt-5">
        {loading ? (
          <></>
        ) : contratos.contratos ? (
          <>
            {/* <h1 className="mt-2 mb-5">Contratos</h1> */}
            <Table striped hover size="lg" responsive>
              <thead>
                <tr>
                  <th className="col-lg-1 col-md-1">Nº contrato</th>
                  <th className="col-lg-1 col-md-1">Sector</th>
                  <th className="col-lg-1 col-md-1">Estado</th>
                  <th className="col-lg-1 col-md-1">Fecha alta</th>
                  <th className="col-lg-1 col-md-1">Fecha baja</th>
                  <th className="col-lg-2 col-md-2">Nombre Completo</th>
                  <th className="col-lg-3 col-md-3">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {contratos.contratos &&
                  contratos.contratos.map((contrato) => {
                    return (
                      <tr key={contrato.name} className="align-middle">
                        <td>{contrato.name}</td>
                        <td>{sectorTypeBadge(contrato.sector_type)}</td>
                        <td>{stateBadge(contrato.state)}</td>
                        <td>{contrato.data_alta ?? "-"}</td>
                        <td>{contrato.data_baixa ?? "-"}</td>
                        <td>{contrato.titular[1]}</td>
                        <td>
                          <Row className="align-items-center justify-content-center my-1">
                            <Col md="8">
                              <Stack gap={2}>
                                <Button
                                  className="button"
                                  onClick={() =>
                                    openGestionArchivos(
                                      contrato.id,
                                      contrato.name,
                                      contrato.sector_type
                                    )
                                  }
                                  title="Gestionar archivos"
                                >
                                  <Stack direction="horizontal" gap={2}>
                                    <LuFiles />
                                    Gestionar archivos
                                  </Stack>
                                </Button>

                                <Button
                                  className="button"
                                  title="Subir archivo"
                                  onClick={() =>
                                    openModalSubirArchivo(
                                      contrato.id,
                                      contrato.name,
                                      contrato.sector_type
                                    )
                                  }
                                >
                                  <Stack direction="horizontal" gap={3}>
                                    <LuFileUp />
                                    Subir archivo
                                  </Stack>
                                </Button>
                                <Button
                                  className="button"
                                  onClick={() =>
                                    openGestionFacturas(
                                      contrato.id,
                                      contrato.name,
                                      contrato.sector_type
                                    )
                                  }
                                  title="Facturas Contrato"
                                >
                                  <Stack direction="horizontal" gap={3}>
                                <LuFileUp />
                                Facturas
                              </Stack>
                              </Button>
                              </Stack>
                            </Col>
                          </Row>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <PaginationComponent 
              totalPages={contratos.total_pages} 
              currentPage={currentPage}
              onPageChange={handlePageChange}
              totalRecords={contratos.total_records}>
            </PaginationComponent>
          </>
        ) : (
          <>
            <Row>
              <Col>
                <h1 className="error-title">
                  <CiWarning />
                </h1>
                <h1 className="text-gray">No hay contratos disponibles.</h1>
              </Col>
            </Row>
            <Row className="justify-content-center mt-2">
              <Col md="auto">
                <Button
                  className="button rounded-5 button-block"
                  variant="secondary"
                  onClick={() => getContratos()}
                >
                  Reintentar
                </Button>
              </Col>
            </Row>
          </>
        )}
      </div>
      <SubirArchivo
        show={showSubirArchivo}
        close={setShowSubirArchivo}
        contrato={contratoActivo}
      />
      <GestionArchivos
        show={showGestionArchivos}
        close={setShowGestionArchivos}
        contrato={contratoActivo}
      />
      <GestionFacturas
        show={showGestionFacturas}
        close={setShowGestionFacturas}
        contrato={contratoActivo}
      />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.Auth.user,
  isAuthenticated: state.Auth.isAuthenticated,
});

export default connect(mapStateToProps)(ContratosList);
